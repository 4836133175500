*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}


html, body, #root{
    height: 100%;
    width: 100%;
    /* background-color: #f5f5f5; */
}

html, body{
    min-height: 100%;
}

body{
    font-family:  "sans-serif", "Arial", "Helvetica";
    font-size: 16px;
    line-height: 1.5;
}

/* Reset CSS */
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
}

a{
  text-decoration: none;
  color: inherit;
}
ul, ol {
  list-style: none;
}

img{
  max-width: 100%;
}
